import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import App from './components/main/App';
import Gallery from './components/main/Gallery';
import Login from './components/admin/Login';
import Dashboard from './components/admin/Dashboard';

import { rootReducer } from './reducers';
import  { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(rootReducer, applyMiddleware(thunk, logger))
root.render(
    <Provider store={store}>
        <Router>
            <Routes>
                <Route exact path='/' element={<App />} />
                <Route path="/:category" element={<Gallery />} />
                <Route path="/admin/login" element={<Login />} />
                <Route path="/admin/:id/dashboard" element={<Dashboard />} />
            </Routes>
        </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
